<!--
 * @Descripttion: 个人信息
 * @version: DBNetGameManagement_v1.0
 * @Author: jerry_huang
 * @Date: 2021-09-02 01:03:31
 * @LastEditTime: 2021-10-10 00:02:15
-->
<template>
  <div style="padding:10px">


    <el-tabs type="border-card">
  <el-tab-pane>
    <span slot="label"><i class="el-icon-user"></i> 个人信息</span>
     <el-descriptions :column="1"
                    >
      <el-descriptions-item labelClassName="infoLable2"
                            label="用户编号">{{memberInfo.member_id}}</el-descriptions-item>
      <el-descriptions-item labelClassName="infoLable2"
                            label="用户名/帐号">{{memberInfo.username}}</el-descriptions-item>
      <el-descriptions-item labelClassName="infoLable2"
                            label="联系方式">{{memberInfo.phone}}</el-descriptions-item>

    </el-descriptions>
  </el-tab-pane>
  <el-tab-pane label="修改密码">
  <el-form ref="changePwdFrom"
               :model="form"
               :rules="rules"
             >
  <el-form-item prop="password_old">
          <el-input type="password"
                    prefix-icon="el-icon-lock"
                    placeholder="请输入旧密码"
                    v-model="form.password_old" />
        </el-form-item>
  <el-form-item prop="password_new">
          <el-input type="password"
                    prefix-icon="el-icon-lock"
                    placeholder="请输入新密码"
                    v-model="form.password_new" />
        </el-form-item>
        <el-form-item prop="password2">
          <el-input type="password"
                    prefix-icon="el-icon-lock"
                    placeholder="请输入确认密码"
                    v-model="form.password2" />
        </el-form-item>
     <el-form-item>
          <el-button :loading="loading"
                     type="danger"
                     v-on:click="onSubmit('changePwdFrom')">确认修改</el-button>
     </el-form-item>

  </el-form>
    
  </el-tab-pane>
</el-tabs>
   
  </div>
</template>

<script>

import { changePwd } from "@/api/member";
export default {
  name: "DbgamewebMemberinfo",

  components: {},

  directives: {},

  data() {
    return {
      memberInfo: [],
       loading: false,
      form:{
password_old:'',
password_new:'',
      },
      rules:{
         password_old: [
          { required: true, message: "旧密码不可为空", trigger: "blur" },
        ],
         password_new: [
          { required: true, message: "新密码不可为空", trigger: "blur" },
        ],
        password2: [
          { required: true, message: "确认密码不可为空", trigger: "blur" },
        ],
      }
    };
  },

  mounted() {
     if (this.$store.state.user.memberInfo) {
      // let memberInfo =this.$store.state.user.memberInfo;
      let memberInfo = JSON.parse(this.$store.state.user.memberInfo);
      this.memberInfo = memberInfo;
      this.uid = memberInfo.member_id;
    } else {

         this.$message.warning('请先登录！');
      this.$router.push({ path: "/login" });
    }
  },

  methods: {
        logout() {
      this.$store
        .dispatch("user/logout", this.form)
        .then(() => {
          console.log("退出成功");
       

        if (!this.$store.state.user.memberInfo) {
          //this.$router.push({ path: "/" });
          location.reload();
        }
          //location.reload();
          // console.log(res)

          //this.loading = false;
        })
        .catch(() => {});
    },
 onSubmit(formName) {

        // 为表单绑定验证功能
        this.$refs[formName].validate((valid) => {
          this.loading = true;
          if (valid) {
            if (this.form.password_new != this.form.password2) {
              this.$message.error({
                message: "输入的两次密码不一致",
                center: true,
              });
              this.loading = false;
              return false;
            }

            // 使用 vue-router 路由到指定页面，该方式称之为编程式导航
            //this.login();

            changePwd(this.form)
              .then(() => {
                delete this.form["password2"];
                delete this.form["password_new"];

                this.$message({
                  showClose: true,
                  message: "密码修改成功",
                  type: "success",
                });
    this.logout()
                //this.$router.push({ path: "/member/person/memberinfo" });
              })
              .catch((error) => {
                console.log(error)
                this.loading = false;
              });
          } else {
            //this.$message.error({ message: "输入账号和密码", center: true });
            this.loading = false;
            return false;
          }
        });
   
    },


  },
};
</script>

<style lang="scss" scoped>
</style>